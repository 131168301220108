export const casinoTabs = {
  roulettetab: {
    SpeedRoulette: {
      href: "/casino/ezugi/SpeedRoulette",
      code: "221000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/speed-roulette1.png", alt: "" },
    },
    DiamondRoulette: {
      href: "/casino/ezugi/DiamondRoulette",
      code: "221003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/diamond-roulette1.png", alt: "" },
    },
    NamasteRoulette: {
      href: "/casino/ezugi/NamasteRoulette",
      code: "221005",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/mamaste-roulette1.png", alt: "" },
    },
    TurkishRoulette: {
      href: "/casino/ezugi/TurkishRoulette",
      code: "5501000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/turkish-roulette.png", alt: "" },
    },
    AutoRoulette: {
      href: "/casino/ezugi/AutoRoulette",
      code: "5001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/auto-roulette.jpg", alt: "" },
    },
    SpeedAutoRoulette: {
      href: "/casino/ezugi/SpeedAutoRoulette",
      code: "221002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/speed-auto-roulette.png", alt: "" },
    },
    OracleRoulette2: {
      href: "/casino/ezugi/OracleRoulette2",
      code: "611001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/oracle-roulette-2.png", alt: "" },
    },
    PortomasoRoulette1: {
      href: "/casino/ezugi/PortomasoRoulette1",
      code: "611004",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/portomaso-roulette-1.jpg", alt: "" },
    },
    ItalianRoulette: {
      href: "/casino/ezugi/ItalianRoulette",
      code: "1000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/italian-roulette.jpg", alt: "" },
    },
    SpanishRoulette: {
      href: "/casino/ezugi/SpanishRoulette",
      code: "411000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/spanish-roulette1.jpg", alt: "" },
    },
    RussianRoulette: {
      href: "/casino/ezugi/RussianRoulette",
      code: "601000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/russian-roulette.jpg", alt: "" },
    },
    FiestaRoulette: {
      href: "/casino/ezugi/FiestaRoulette",
      code: "431001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/fiesta-roulette1.jpg", alt: "" },
    },
    PrestigeAutoRoulette: {
      href: "/casino/ezugi/PrestigeAutoRoulette",
      code: "221004",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/roulette/prestige-auto-oulette.jpg", alt: "" },
    },
    HandCasinoHoldem: {
      href: "/casino/evolution/HandCasinoHoldem",
      code: 507000,
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/11.png", alt: "" },
    },
  },
  baccarattab: {
    Baccaratpro: {
      href: "/casino/ezugi/Baccaratpro",
      code: "100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat01.png", alt: "" },
    },
    Nocommissiobaccarat: {
      href: "/casino/ezugi/Nocommissiobaccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat02.png", alt: "" },
    },
    Knockoutbaccarat: {
      href: "/casino/ezugi/Knockoutbaccarat",
      code: "120",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat03.png", alt: "" },
    },
    Super6baccarat: {
      href: "/casino/ezugi/Super6baccarat",
      code: "32100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat04.png", alt: "" },
    },
    Marinabaccarat: {
      href: "/casino/ezugi/Marinabaccarat",
      code: "130",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat05.png", alt: "" },
    },

    Marina02baccarat: {
      href: "/casino/ezugi/Marina02baccarat",
      code: "32101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat06.png", alt: "" },
    },
    Salsabaccarat: {
      href: "/casino/ezugi/Salsabaccarat",
      code: "170",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat07.png", alt: "" },
    },
    Baccarat: {
      href: "/casino/ezugi/Baccarat",
      code: "106",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat08.png", alt: "" },
    },
    Baccaratpro02: {
      href: "/casino/ezugi/Baccaratpro02",
      code: "101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat09.png", alt: "" },
    },
    SpeedBaccarat: {
      href: "/casino/ezugi/SpeedBaccarat",
      code: "105",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat010.png", alt: "" },
    },
    Marina03baccarat: {
      href: "/casino/ezugi/Marina03baccarat",
      code: "32103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/baccarat/baccarat010.png", alt: "" },
    },
  },
  AndarBahartab: {
    AndarBaharLive: {
      href: "/casino/ezugi/AndarBaharLive",
      code: "228000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/AndarBahar01.png", alt: "" },
    },
    AndarBaharMarnia: {
      href: "/casino/ezugi/AndarBaharMarnia",
      code: "328000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/andarbahar/AndarBahar02.png", alt: "" },
    },
  },
  TeenPattitab: {
    TeenPatti01: {
      href: "/casino/ezugi/TeenPatti01",
      code: "227100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker01.png", alt: "" },
    },
    TeenPatti02: {
      href: "/casino/ezugi/TeenPatti02",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker02.png", alt: "" },
    },
    TeenPatti03: {
      href: "/casino/ezugi/TeenPatti03",
      code: "227103",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker03.png", alt: "" },
    },
    TeenPatti04: {
      href: "/casino/ezugi/TeenPatti04",
      code: "507000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker04.png", alt: "" },
    },
    TeenPatti05: {
      href: "/casino/ezugi/TeenPatti05",
      code: "227101",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker05.png", alt: "" },
    },
    TeenPatti06: {
      href: "/casino/ezugi/TeenPatti06",
      code: "507000",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker06.jpeg", alt: "" },
    },
    TeenPatti07: {
      href: "/casino/ezugi/TeenPatti07",
      code: "150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker07.png", alt: "" },
    },
    TeenPatti08: {
      href: "/casino/ezugi/TeenPatti08",
      code: "150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/poker/Poker08.png", alt: "" },
    },
  },
  Blackjacktab: {
    Blackjack: {
      href: "/casino/ezugi/Blackjack",
      code: "201",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack01.png", alt: "" },
    },
    GoldBlackjack: {
      href: "/casino/ezugi/GoldBlackjack",
      code: "1",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack02.png", alt: "" },
    },
    BlackjackPro: {
      href: "/casino/ezugi/BlackjackPro",
      code: "224",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack03.png", alt: "" },
    },
    VIPBlackjack: {
      href: "/casino/ezugi/VIPBlackjack",
      code: "225",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack04.png", alt: "" },
    },
    BlackjackAutoSplit: {
      href: "/casino/ezugi/BlackjackAutoSplit",
      code: "226",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack05.png", alt: "" },
    },
    TurkceBlackjack: {
      href: "/casino/ezugi/TurkceBlackjack",
      code: "501",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack06.png", alt: "" },
    },
    LiveBlackjack: {
      href: "/casino/ezugi/LiveBlackjack",
      code: "221",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack07.png", alt: "" },
    },
    RumbaBlackjack: {
      href: "/casino/ezugi/RumbaBlackjack",
      code: "411",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack09.png", alt: "" },
    },
    DiamondBlackjack: {
      href: "/casino/ezugi/DiamondBlackjack",
      code: "225",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack010.png", alt: "" },
    },
    Rumba02Blackjack: {
      href: "/casino/ezugi/Rumba02Blackjack",
      code: "414",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack011.png", alt: "" },
    },
    VipBlackjack: {
      href: "/casino/ezugi/VipBlackjack",
      code: "224",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack011.png", alt: "" },
    },
    Vip02Blackjack: {
      href: "/casino/ezugi/Vip02Blackjack",
      code: "226",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack013.png", alt: "" },
    },
    TurkishBlackjack: {
      href: "/casino/ezugi/TurkishBlackjack",
      code: "501",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack015.png", alt: "" },
    },
    BlackjackLive: {
      href: "/casino/ezugi/BlackjackLive",
      code: "422",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack016.png", alt: "" },
    },
    BlackjackTurkce: {
      href: "/casino/ezugi/BlackjackTurkce",
      code: "5",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack016.png", alt: "" },
    },
    PlatinumBlackjack: {
      href: "/casino/ezugi/PlatinumBlackjack",
      code: "5051",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/blackjack/Blackjack019.png", alt: "" },
    },
  },
  thirtytwoCardtab: {
    thirtytwoCards: {
      href: "/casino/ezugi/thirtytwoCards",
      code: "228002",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/card/32cards01.png", alt: "" },
    },
  },
  sevenLuckytab: {
    sevenLucky: {
      href: "/casino/ezugi/sevenLucky",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/lucky7/Lucky011.png", alt: "" },
    },
  },
  sicbotab: {
    sicbodice01: {
      href: "/casino/ezugi/sicbodice01",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice01.png", alt: "" },
    },
    sicbodice02: {
      href: "/casino/ezugi/sicbodice02",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice02.png", alt: "" },
    },
    sicbodice03: {
      href: "/casino/ezugi/sicbodice03",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice03.png", alt: "" },
    },
    sicbodice04: {
      href: "/casino/ezugi/sicbodice04",
      code: "224100",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice04.jpeg", alt: "" },
    },
    sicbodice05: {
      href: "/casino/ezugi/sicbodice05",
      code: "228001",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/dice/Dice05.png", alt: "" },
    },
  },
  spribeGames: {
    mines: {
      href: "/casino/spribe/mines",
      code: "mines",
      casino: "spribe",
      provider: "mines",
      homeUrl: "",
      cover: { src: "./images/spribe/02.jpg", alt: "" },
    },
    goal: {
      href: "/casino/spribe/goal",
      code: "goal",
      casino: "spribe",
      provider: "goal",
      homeUrl: "",
      cover: { src: "./images/spribe/06.png", alt: "" },
    },
    dice: {
      href: "/casino/spribe/dice",
      code: "dice",
      casino: "spribe",
      provider: "dice",
      homeUrl: "",
      cover: { src: "./images/spribe/07.jpg", alt: "" },
    },
    aviator: {
      href: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: "",
      cover: { src: "./images/spribe/03.png", alt: "" },
    },
    pilnko: {
      href: "/casino/spribe/Pilnko",
      code: "pilnko",
      casino: "spribe",
      provider: "pilnko",
      homeUrl: "",
      cover: { src: "./images/spribe/08.jpg", alt: "" },
    },
    miniroulette: {
      href: "/casino/spribe/miniroulette",
      code: "miniroulette",
      casino: "spribe",
      provider: "miniroulette",
      homeUrl: "",
      cover: { src: "./images/spribe/04.png", alt: "" },
    },
    hilo: {
      href: "/casino/spribe/hilo",
      code: "hilo",
      casino: "spribe",
      provider: "hilo",
      homeUrl: "",
      cover: { src: "./images/spribe/05.png", alt: "" },
    },
  },
  Slots: {
    twinspin: {
      href: "/casino/ezugi/twinspin",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/twin-and-spin.jpg", alt: "" },
    },
    gonzoquest: {
      href: "/casino/ezugi/gonzoquest",
      code: "1100018",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/gonzo-treasure-hunt.jpg", alt: "" },
    },

    asgardianstones: {
      href: "/casino/ezugi/asgardianstones",
      code: "1100096",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/asgardianstones1.jpg", alt: "" },
    },
    secrets: {
      href: "/casino/ezugi/secrets",
      code: "1100106",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/secrets.jpg", alt: "" },
    },
    grandwheel: {
      href: "/casino/ezugi/grandwheel",
      code: "1100150",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/Grand-wheel.jpg", alt: "" },
    },
    Strike777: {
      href: "/casino/ezugi/Strike777",
      code: "1100031",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Nights1000: {
      href: "/casino/ezugi/Nights1000",
      code: "1100003",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CashUltimate: {
      href: "/casino/ezugi/CashUltimate",
      code: "1100070",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    CashVolt: {
      href: "/casino/ezugi/CashVolt",
      code: "1100071",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DazzleMegaways: {
      href: "/casino/ezugi/DazzleMegaways",
      code: "1100083",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    DivinieFortuneMegaways: {
      href: "/casino/ezugi/DivinieFortuneMegaways",
      code: "1100089",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    Dynamiteriches: {
      href: "/casino/ezugi/Dynamiteriches",
      code: "1100073",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FortuneHouse: {
      href: "/casino/ezugi/FortuneHouse",
      code: "1100007",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    FruitShopMegaways: {
      href: "/casino/ezugi/FruitShopMegaways",
      code: "1100094",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenCryptex: {
      href: "/casino/ezugi/GoldenCryptex",
      code: "1100147",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GoldenTsar: {
      href: "/casino/ezugi/GoldenTsar",
      code: "1100149",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    GorillaKingdom: {
      href: "/casino/ezugi/GorillaKingdom",
      code: "1100088",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    MystryReels: {
      href: "/casino/ezugi/MystryReels",
      code: "1100061",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    RageoftheSea: {
      href: "/casino/ezugi/RageoftheSea",
      code: "1100116",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    ReelKingMegaways: {
      href: "/casino/ezugi/ReelKingMegaways",
      code: "1100052",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TurnFortune: {
      href: "/casino/ezugi/TurnFortune",
      code: "1100041",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    TwinSpinMegaways: {
      href: "/casino/ezugi/TwinSpinMegaways",
      code: "1100039",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    WillysChills: {
      href: "/casino/ezugi/WillysChills",
      code: "1100128",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
    VikingsSlots: {
      href: "/casino/ezugi/VikingsSlots",
      code: "1100124",
      casino: "ezugi",
      provider: "",
      homeUrl: "",
      cover: { src: "./images/slots/777_Strike.png", alt: "" },
    },
  },
};
