import React from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import Footer from "../../../../containers/Footer";

import SpribeGameIcon from "../../../../assets/images/games-icon/Slots1.png";
import PlayBtn from "../../../../assets/images/play-btn.png";

import Slots1 from "../../../../assets/images/slots/casino/Aviator.jpg";
import Slots2 from "../../../../assets/images/slots/casino/Twin-spin.jpg";
import Slots3 from "../../../../assets/images/slots/casino/Gonzos-hunt.jpg";
import Slots4 from "../../../../assets/images/slots/casino/asgardianstones.jpg";
import Slots5 from "../../../../assets/images/slots/casino/secret-of-atlantis.jpg";
import Slots6 from "../../../../assets/images/slots/casino/grand-wheel.jpg";
import Slots7 from "../../../../assets/images/slots/casino/777_Strike.jpg";
import Slots8 from "../../../../assets/images/slots/casino/10001Nights.jpg";
import Slots9 from "../../../../assets/images/slots/casino/Cash_Ultimate.jpg";
import Slots10 from "../../../../assets/images/slots/casino/Cash_Volt.jpg";
import Slots11 from "../../../../assets/images/slots/casino/Dazzle_Me_Megaways.jpg";
import Slots12 from "../../../../assets/images/slots/casino/Divine_Fortune_Megaways.jpg";
import Slots13 from "../../../../assets/images/slots/casino/Dynamite_Riches.jpg";
import Slots14 from "../../../../assets/images/slots/casino/FortuneHouse.jpg";
import Slots15 from "../../../../assets/images/slots/casino/Fruit_Shop_Megaways.jpg";
import Slots16 from "../../../../assets/images/slots/casino/Golden_Cryptex.jpg";
import Slots17 from "../../../../assets/images/slots/casino/Golden_star.jpg";
import Slots18 from "../../../../assets/images/slots/casino/Gorilla_Kingdom.jpg";
import Slots19 from "../../../../assets/images/slots/casino/Mystery_Reels_Megaways.jpg";
import Slots20 from "../../../../assets/images/slots/casino/Rage_Of_The_Seas.jpg";
import Slots22 from "../../../../assets/images/slots/casino/Reel_King_Mega.jpg";
import Slots24 from "../../../../assets/images/slots/casino/Turn_Your_Fortune.jpg";
import Slots25 from "../../../../assets/images/slots/casino/Twin_Spin_Megaways.jpg";
import Slots27 from "../../../../assets/images/slots/casino/Willys_Hot_Chillies.jpg";

const Slots = () => {
  return (
    <div>
      <div>
        <HeaderAfterLogin />
      </div>
      <div className="slots-section">
        <div className="games-section">
          <div className="game-heading">
            <img src={SpribeGameIcon} />
            <span>Slots</span>
          </div>
          <ul>
            <li>
              <a href="/casino/spribe/aviator">
                <img src={Slots1} alt="Live casino game" />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi/twinspin">
                <img src={Slots2} alt="Live casino game" />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi/gonzoquest">
                <img src={Slots3} alt="Live casino game" />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi/asgardianstones">
                <img src={Slots4} alt="Live casino game" />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi/secrets">
                <img src={Slots5} alt="Live casino game" />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi/grandwheel">
                <img src={Slots6} alt="Live casino game" />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi/Strike777">
                <img src={Slots7} />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi/Nights1000">
                <img src={Slots8} />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi/CashUltimate">
                <img src={Slots9} />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi/CashVolt">
                <img src={Slots10} />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi/DazzleMegaways">
                <img src={Slots11} />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi/DivinieFortuneMegaways">
                <img src={Slots12} />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi/Dynamiteriches">
                <img src={Slots13} />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi/FortuneHouse">
                <img src={Slots14} />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi/FruitShopMegaways">
                <img src={Slots15} />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi/GoldenCryptex">
                <img src={Slots16} />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi/GoldenTsar">
                <img src={Slots17} />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>

            <li>
              <a href="/casino/ezugi/GorillaKingdom">
                <img src={Slots18} />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi/MystryReels">
                <img src={Slots19} />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi/RageoftheSea">
                <img src={Slots20} alt="Live casino game" />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>

            <li>
              <a href="/casino/ezugi/ReelKingMegaways">
                <img src={Slots22} alt="Live casino game" />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>

            <li>
              <a href="/casino/ezugi/TurnFortune">
                <img src={Slots24} alt="Live casino game" />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>
            <li>
              <a href="/casino/ezugi/TwinSpinMegaways">
                <img src={Slots25} alt="Live casino game" />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>

            <li>
              <a href="/casino/ezugi/WillysChills">
                <img src={Slots27} alt="Live casino game" />
                <div className="casino_overlay">
                  <img src={PlayBtn} />
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default Slots;
