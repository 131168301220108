import React from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import logo from "../assets/svg/logo_new.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";

import ProviderImg from "../assets/images/games-icon/validate.png";
import Facebook1 from "../assets/images/games-icon/facebook.png";
import Instagram1 from "../assets/images/games-icon/instagram.png";
import Telegram1 from "../assets/images/games-icon/telegram1.png";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CasinoProviders } from "../lib/data";

import HomeFoot from "../assets/images/games-icon/logo.png";
import Casino1 from "../assets/images/games-icon/casinoezugi-icon.png";
import Sports1 from "../assets/images/games-icon/evolution-icon.png";
import Support from "../assets/images/games-icon/Aviator-icon.png";
import Deposit from "../assets/images/games-icon/Deposit1.png";
import Evolution from "../assets/images/games-icon/Ezugi.png";

const Footer = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  return (
    <footer className="footer">
      <div className="container">
        <div className="providers_img d-none">
          <a href="#" style={{ width: "65px" }}>
            <img src={RNG} loading="lazy" alt="RNG Certified" />
          </a>
          <a href="#" style={{ width: "80px" }}>
            <img src={Gambling} loading="lazy" alt="Gambling Commission" />
          </a>
          <a href="#" style={{ width: "130px" }}>
            <img src={ResponsibleG} loading="lazy" alt="Responsible Gaming" />
          </a>
          <a href="#" style={{ width: "120px" }}>
            <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
          </a>
        </div>

        <div className="helpline d-none">
          <a
            className="panel"
            href={
              appDetails?.WHATS_APP_URL != ""
                ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                : "#"
            }
            target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
          >
            Call Us <p>{appDetails?.WHATS_APP_URL}</p>
          </a>
        </div>

        <div className="footer-links d-none">
          <ul>
            <li>
              <a href="/contactus">Contact Us</a>
            </li>
            <li>
              <a href="/privacypolicy ">Privacy Policy</a>
            </li>
            <li>
              <a href="/responsiblegame">Responsible Gaming</a>
            </li>
            <li>
              <a href="/fairplay">Fair Play</a>
            </li>
            <li>
              <a href="/gamesrule">Games Rules</a>
            </li>
            <li>
              <a href="/terms">Terms and Conditions</a>
            </li>
          </ul>
        </div>

        <div className="d-flex justify-content-center mt-2 d-none">
          <p style={{ color: "rgb(204, 204, 204)" }}>
            Accept, process &amp; disburse digital payments for your business.
          </p>
        </div>
        <div className="d-flex justify-content-center mt-2 d-none">
          <img
            src={PaymentMod}
            loading="lazy"
            style={{ width: "250px" }}
            alt="Payment Otions"
          />
        </div>

        <div className="d-flex justify-content-center mt-3 mb-3 d-none">
          <img
            src={Betfair}
            loading="lazy"
            className="mb-3 betfair"
            alt="Betfair Logo"
          />
        </div>

        <p className="d-none">{appDetails?.FOOTER_TEXT || ""}</p>
        <div className="paymentImages d-none">
          <ul>
            <li>
              <img src="./images/sewa.jpeg" />
            </li>
            <li>
              <img src="./images/khalti.jpeg" />
            </li>
          </ul>
        </div>

        <div className="social-icons d-none">
          <ul>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                    : "#"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                {/* <span className="notification-count">2</span> */}
                <img src={Whatsapp} alt="Whatsapp Icon" />
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={appDetails?.TELEGRAM != "" ? appDetails?.TELEGRAM : "#"}
                target={appDetails?.TELEGRAM != "" ? "_blank" : ""}
              >
                <img src={Telegram} alt="Telegram Icon" />
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={appDetails?.FACEBOOK != "" ? appDetails?.FACEBOOK : "#"}
                target={appDetails?.FACEBOOK != "" ? "_blank" : ""}
              >
                <img src="/images/facebook-512.svg" alt="Facebook Icon" />
              </a>
            </li>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.INSTATGRAM != "" ? appDetails?.INSTATGRAM : "#"
                }
                target={appDetails?.INSTATGRAM != "" ? "_blank" : ""}
              >
                <img src={Instagram} alt="Instagram Icon" />
              </a>
            </li>
          </ul>
        </div>

        <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 d-block d-lg-none">
          <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
            <li>
              <a href="/casino/ezugi">
                <img src={Casino1} alt="Exchange Icon" />
                <span>Ezugi</span>
              </a>
            </li>

            <li>
              <a href="/Slots">
                <img src={Sports1} alt="Exchange Icon" />
                <span>Slots</span>
              </a>
            </li>
            <li>
              <a className="active" href="/home">
                {/* <HomeIcon /> */}
                <img src={HomeFoot} />
                {/* <span>Home</span> */}
              </a>
            </li>
            <li>
              <a href="/casino/spribe/aviator">
                <img src={Support} alt="Exchange Icon" />
                <span>Aviator</span>
              </a>
            </li>
            <li>
              <a href="/casino/evolution">
                <img src={Evolution} alt="Exchange Icon" />
                <span>Evolution</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="container-fluid">
        <div className="mainFooter">
          <div className="footer-content">
            <div className="footer-nav">
              <h3>SUPPORT</h3>
              <ul>
                <li>
                  <a className="contact" href="tel:6262414444">
                    6262414444
                  </a>
                </li>
                <li>
                  <a href="/responsiblegame">Responsible Gambling</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-content">
            <div className="footer-nav">
              <h3>COMPANY</h3>
              <ul>
                <li>
                  <a href="/aboutus">About Us</a>
                </li>
                <li>
                  <a href="/contactus">Contact Us</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-content">
            <div className="footer-nav">
              <h3>LEGAL</h3>
              <ul>
                <li>
                  <a href="/privacypolicy">Privacy</a>
                </li>
                <li>
                  <a href="/terms">Terms</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-content">
            <div className="footer-nav">
              <div className="providers_img">
                <a href="#" style={{ width: "65px" }}>
                  <img src={RNG} loading="lazy" alt="RNG Certified" />
                </a>
                <a href="#" style={{ width: "80px" }}>
                  <img
                    src={Gambling}
                    loading="lazy"
                    alt="Gambling Commission"
                  />
                </a>
                <a href="#" style={{ width: "130px" }}>
                  <img
                    src={ResponsibleG}
                    loading="lazy"
                    alt="Responsible Gaming"
                  />
                </a>
                <a href="#" style={{ width: "120px" }}>
                  <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
                </a>
              </div>
              <div className="providerImg">
                <img src={ProviderImg} />
              </div>
            </div>
          </div>
        </div>

        <div className="general-foot-content">
          <p>
            In order to register for this website, the user is required to
            accept the <a href="/terms">General Terms and Conditions</a>. In the
            event the General Terms and Conditions are updated, existing users
            may choose to discontinue using the products and services before the
            said update shall become effective, which is a minimum of two weeks
            after it has been announced.
          </p>
        </div>
        <div className="age18 text-center">
          <p className="mt-3">
            Online gambling 18+: Gamble responsibly within legal age limits
          </p>
        </div>
        <div className="copy-right-social-icon">
          <div className="copyContent">
            {/* <p>
              Copyright © 2023 <span>elevenstarssonlinegaming</span> | All
              rights reserved.
            </p> */}
            <p style={{ maxWidth: "600px", textAlign: "center" }}>
              I hereby certify that ELEVENSTARSS ONLINE GAMING PRIVATE LIMITED
              Is register With GOVERNMENT OF INDIA MINISTRY OF CORPORATE AFFAIRS
            </p>
          </div>
          <div className="socila-icons d-none">
            <a href="#">
              <img src={Facebook1} />
            </a>
            <a href="#">
              <img src={Instagram1} />
            </a>
            <a href="#">
              <img src={Telegram1} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
