import React from "react";
import BeforeLoginFooter from "../../../../containers/BeforeLoginFooter";
import Header from "../../../../containers/Header";

function BeforeAboutUs() {
  return (
    <div>
      <div>
        <Header />
      </div>
      <div>
        <main className="main">
          <div className="container">
            <h2 className="sectionTitle">About Us</h2>
            <div className="privay-content">
              <p>
                Hello, Dear friends, Welcome to Eleven Starss Online Gaming
                also, we are happy you want to know something more about our
                site
              </p>
              <p>
                So, basically, nowadays people are more like online gaming
                that's why we also, take forward a step to help you.
              </p>
              <p>
                Our first wish is to provide you with a better platform for you.
              </p>
              <p>Also, we are trying to provide latest game for playing.</p>

              <h3>What is Our Goal?</h3>
              <p>
                There are millions of websites created every day, also, there is
                much fake content spread on the internet.
              </p>
              <p>
                So, Our main goal is to provide you with 100% Original and Safe
                platform that provides you a great and better experience on the
                world wide web.
              </p>
              <p>
                We mainly focus on our service so and improving it regularly to
                provide a better user experience to all users.
              </p>
              <p>
                Basically, we focus on the Gaming niche so, our main priority is
                to search for new content and present it in front of you to
                learn something new.
              </p>

              <h3>What is our Service?</h3>
              <p>
                We are mainly focused on the Gaming category so, we provide
                Gaming Platform if you are interested in the Gaming category
                then you can visit daily to get more latest games.
              </p>

              <h3>Play Online Gaming</h3>
              <p>
                On our website Eleven Starss Online Gaming you can play all
                Games also, we focus on many other categories and we hope you
                like also, the content of other categories that are maintained
                on our website. So, you can visit our website homepage to know
                all gaming category details here you can visit our homepage{" "}
                <a href="https://elevenstarssonlinegaming.com/."> Click here</a>
              </p>
            </div>
          </div>
        </main>
      </div>
      <div>
        <BeforeLoginFooter />
      </div>
    </div>
  );
}

export default BeforeAboutUs;
