import React from "react";

export default function StatementIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.951"
      height="25.999"
      viewBox="0 0 18.951 25.999"
    >
      <g
        id="Group_1184"
        data-name="Group 1184"
        transform="translate(-291.562 -149.716)"
      >
        <path
          id="Path_2816"
          data-name="Path 2816"
          d="M310.011,164.594q0,4.1,0,8.192a2.234,2.234,0,0,1-2.438,2.429H294.5a2.237,2.237,0,0,1-2.435-2.434v-8.5q0-5.805,0-11.611a2.242,2.242,0,0,1,2.445-2.456q4.824,0,9.648,0a.8.8,0,0,1,.617.254c1.656,1.662,3.324,3.311,4.967,4.987a1.157,1.157,0,0,1,.261.735c.02,2.072.012,4.146.012,6.219Z"
          transform="translate(0 0)"
          fill="none"
          stroke="#dcde9f"
          strokeWidth="1"
        />
        <path
          id="Path_2817"
          data-name="Path 2817"
          d="M301.874,175.631q2.729,0,5.458,0a.622.622,0,0,1,.681.776.574.574,0,0,1-.5.495,1.209,1.209,0,0,1-.212.008H296.473c-.451,0-.7-.211-.725-.6a.611.611,0,0,1,.668-.681q2.032,0,4.064,0Z"
          transform="translate(-0.844 -5.821)"
          fill="#dcde9f"
        />
        <path
          id="Path_2818"
          data-name="Path 2818"
          d="M301.884,161.708q-2.7,0-5.4,0c-.409,0-.639-.153-.721-.467a.625.625,0,0,1,.635-.814c1.072-.007,2.143,0,3.214,0h7.672c.443,0,.68.173.729.52a.63.63,0,0,1-.7.762Q304.6,161.711,301.884,161.708Z"
          transform="translate(-0.842 -2.338)"
          fill="#dcde9f"
        />
        <path
          id="Path_2819"
          data-name="Path 2819"
          d="M306.244,165.5c-.908,0-1.817.006-2.725,0a.582.582,0,0,1-.621-.633.593.593,0,0,1,.618-.641q2.759-.009,5.518,0a.588.588,0,0,1,.631.627.605.605,0,0,1-.636.659c-.8.014-1.594,0-2.392,0h-.392Z"
          transform="translate(-2.482 -3.208)"
          fill="#dcde9f"
        />
        <path
          id="Path_2820"
          data-name="Path 2820"
          d="M306.278,169.3c-.91,0-1.819.008-2.73,0a.63.63,0,0,1-.605-.9.623.623,0,0,1,.631-.383c.969,0,1.938,0,2.907,0,.817,0,1.634-.012,2.452-.013a.645.645,0,0,1,.728.664c0,.408-.247.634-.714.635-.89,0-1.779,0-2.669,0Z"
          transform="translate(-2.479 -4.073)"
          fill="#dcde9f"
        />
        <path
          id="Path_2821"
          data-name="Path 2821"
          d="M306.271,171.829c.888,0,1.777,0,2.665,0,.482,0,.732.23.725.652-.005.4-.258.631-.722.631q-2.666,0-5.331,0a.625.625,0,0,1-.7-.493.617.617,0,0,1,.635-.788c.909-.009,1.818,0,2.727,0Z"
          transform="translate(-2.48 -4.95)"
          fill="#dcde9f"
        />
        <path
          id="Path_2822"
          data-name="Path 2822"
          d="M296.016,167.578c.034,0,.058,0,.082,0a6.8,6.8,0,0,0,1.077-.051,1.9,1.9,0,0,0,1.027-.473,1.029,1.029,0,0,0,.348-.6.318.318,0,0,0,.005-.046.232.232,0,0,0,0-.035h-2.789l.239-.357c.057-.086.117-.17.171-.258a.1.1,0,0,1,.1-.053q1.044,0,2.089,0h.086a1.409,1.409,0,0,0-.086-.141,1.032,1.032,0,0,0-.458-.311,3.508,3.508,0,0,0-.863-.19,10.437,10.437,0,0,0-1.251-.045l-.048,0,.177-.252c.1-.138.2-.274.294-.415a.108.108,0,0,1,.1-.051h4.616c-.052.086-.1.161-.143.234-.1.16-.205.318-.306.478a.094.094,0,0,1-.09.049c-.349,0-.7,0-1.048,0h-.074c.065.126.13.247.189.371a1.378,1.378,0,0,1,.079.2.069.069,0,0,0,.079.058c.407,0,.813,0,1.22.005h.067c-.044.069-.08.13-.12.189-.116.173-.234.346-.348.521a.081.081,0,0,1-.079.042c-.227,0-.456,0-.683,0-.045,0-.066.007-.08.059a1.856,1.856,0,0,1-1.029,1.237,3.489,3.489,0,0,1-1.059.314l-.261.04,2.769,3.206a.6.6,0,0,1-.067.007c-.382,0-.765,0-1.147,0a.145.145,0,0,1-.093-.046c-.352-.389-.7-.78-1.049-1.17q-.8-.9-1.6-1.792a.165.165,0,0,1-.047-.123C296.017,167.98,296.016,167.783,296.016,167.578Z"
          transform="translate(-0.844 -3.225)"
          fill="#dcde9f"
        />
        <path
          id="Path_2823"
          data-name="Path 2823"
          d="M307.646,150.216v5.491h5.937"
          transform="translate(-3.569)"
          fill="none"
          stroke="#dcde9f"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}
